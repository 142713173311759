import { PROPERTY_TYPE_DEFAULT } from '~/config/property-types';

/*
 * @TODO: URL generation for this links isn't taking into account URL translation for other languages.
 * To fix this we might need to have all details translated or it is the responsibility of the destination page, to
 * translated to the correct url as it does at the moment, but it's one more redirect
 */

export const CARDS_COLLECTION = computed(() => {
  const { useUrl } = useUrls();
  const { t } = useI18n();

  const actualPropertyTypeObj = PROPERTY_TYPE_DEFAULT;

  const CARDS = {
    casaAngel: {
      place: 'Casa Angel Hostel',
      city: 't_OAXACAHP',
      country: 'Mexico',
      assetUrl: 'https://a.hwstatic.com/image/upload/f_auto,q_50,h_245/v1645099928/pwa/hostelcards/CasaAngelHostel.jpg',
      isMovie: false,
      url: useUrl.getPropertyPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        propertyId: '40426',
        urlFriendlyProperty: 'casa-angel-hostel',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'north-america',
        urlFriendlyCountry: 'mexico',
        urlFriendlyCity: 'oaxaca',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'north-america',
        urlFriendlyCountry: 'mexico',
      }),
    },
    viajero: {
      place: 'Viajero Tayrona',
      city: 't_BURITACAHP',
      country: 'Colombia',
      assetUrl: 'https://a.hwstatic.com/video/upload/q_50,h_245/v1645099930/pwa/hostelcards/ViajeroTayronaHostel.mp4',
      isMovie: true,
      url: useUrl.getPropertyPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        propertyId: '283178',
        urlFriendlyProperty: 'viajero-tayrona-hostel-and-ecohabs',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'south-america',
        urlFriendlyCountry: 'colombia',
        urlFriendlyCity: 'buritaca',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'south-america',
        urlFriendlyCountry: 'colombia',
      }),
    },
    jackal: {
      place: 'Salty Jackal',
      city: 't_SWAKOPMUNDHP',
      country: 'Namibia',
      assetUrl: 'https://a.hwstatic.com/image/upload/f_auto,q_50,h_245/v1645099930/pwa/hostelcards/SaltyJackal.jpg',
      isMovie: false,
      url: useUrl.getPropertyPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        propertyId: '285339',
        urlFriendlyProperty: 'salty-jackal-backpackers-and-surf-camp',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'africa',
        urlFriendlyCountry: 'namibia',
        urlFriendlyCity: 'swakopmund',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'africa',
        urlFriendlyCountry: 'namibia',
      }),
    },
    gracia: {
      place: 'Casa Gracia',
      city: 't_BARCELONAHP',
      country: 'Spain',
      assetUrl: 'https://a.hwstatic.com/video/upload/q_50,h_245/v1645099928/pwa/hostelcards/CasaGraciaBarcelona.mp4',
      isMovie: true,
      url: useUrl.getPropertyPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        propertyId: '45620',
        urlFriendlyProperty: 'casa-gracia-barcelona-hostel',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'europe',
        urlFriendlyCountry: 'spain',
        urlFriendlyCity: 'barcelona',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'europe',
        urlFriendlyCountry: 'spain',
      }),
    },
    generator: {
      place: 'Generator',
      city: 't_BARCELONAHP',
      country: 'Spain',
      assetUrl: 'https://a.hwstatic.com/image/upload/q_50,h_245/v1645099928/pwa/hostelcards/Generator.jpg',
      isMovie: false,
      url: useUrl.getPropertyPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        propertyId: '59987',
        urlFriendlyProperty: 'generator-barcelona',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'europe',
        urlFriendlyCountry: 'spain',
        urlFriendlyCity: 'barcelona',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'europe',
        urlFriendlyCountry: 'spain',
      }),
    },
    sydney: {
      place: 'Base Sydney',
      city: 't_SYDNEYHP',
      country: 'Australia',
      assetUrl: 'https://a.hwstatic.com/image/upload/f_auto,q_50,h_245/v1645099928/pwa/hostelcards/BaseSydney.jpg',
      isMovie: false,
      url: useUrl.getPropertyPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        propertyId: '905',
        urlFriendlyProperty: 'nomads-sydney',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'oceania',
        urlFriendlyCountry: 'australia',
        urlFriendlyCity: 'sydney',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'oceania',
        urlFriendlyCountry: 'australia',
      }),
    },
    samui: {
      place: 'Lub D Koh Samui',
      city: 't_KOHSAMUIHP',
      country: 'Thailand',
      assetUrl: 'https://a.hwstatic.com/video/upload/q_50,h_245/v1645099928/pwa/hostelcards/LubDKohSamui.mp4',
      isMovie: true,
      url: useUrl.getPropertyPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        propertyId: '292265',
        urlFriendlyProperty: 'lub-d-koh-samui-chaweng-beach',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'asia',
        urlFriendlyCountry: 'thailand',
        urlFriendlyCity: 'koh-samui',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'asia',
        urlFriendlyCountry: 'thailand',
      }),
    },
    canale: {
      place: 'Canale Hostel',
      city: 't_BANGKOKHP',
      country: 'Thailand',
      assetUrl: 'https://a.hwstatic.com/image/upload/f_auto,q_50,h_245/v1645099929/pwa/hostelcards/CanaleHostel.jpg',
      isMovie: false,
      url: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'asia',
        urlFriendlyCountry: 'thailand',
        urlFriendlyCity: 'bangkok',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'asia',
        urlFriendlyCountry: 'thailand',
        urlFriendlyCity: 'bangkok',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'asia',
        urlFriendlyCountry: 'thailand',
      }),
    },
    selina: {
      place: 'Selina Cancun',
      city: 't_CANCUNHP',
      country: 'Mexico',
      assetUrl: 'https://a.hwstatic.com/video/upload/q_50,h_245/v1645099930/pwa/hostelcards/SelinaCancun.mp4',
      isMovie: true,
      url: useUrl.getPropertyPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        propertyId: '290322',
        urlFriendlyProperty: 'selina-cancun-laguna-hotel-zone',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'north-america',
        urlFriendlyCountry: 'mexico',
        urlFriendlyCity: 'cancun',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'north-america',
        urlFriendlyCountry: 'mexico',
      }),
    },
    milan: {
      place: 'Queen Hostel Milan',
      city: 't_MILANHP',
      country: 'Italy',
      assetUrl: 'https://a.hwstatic.com/video/upload/q_50,h_245/v1645099930/pwa/hostelcards/QueenHostelMilan.mp4',
      isMovie: true,
      url: useUrl.getPropertyPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        propertyId: '267025',
        urlFriendlyProperty: 'quo-milano',
      }),
      cityUrl: useUrl.getCityPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'europe',
        urlFriendlyCountry: 'italy',
        urlFriendlyCity: 'milan',
      }),
      countryUrl: useUrl.getCountryPageUrl({
        propertyTypeSlug: t(actualPropertyTypeObj?.slug),
        urlFriendlyContinent: 'europe',
        urlFriendlyCountry: 'italy',
      }),
    },
  };

  return {
    firstRow: [CARDS.casaAngel, CARDS.viajero, CARDS.jackal, CARDS.gracia],
    secondRow: [CARDS.generator, CARDS.sydney, CARDS.samui, CARDS.canale],
    desktop: [
      CARDS.gracia,
      CARDS.jackal,
      CARDS.casaAngel,
      CARDS.viajero,
      CARDS.generator,
      CARDS.selina,
      CARDS.sydney,
      CARDS.milan,
      CARDS.canale,
      CARDS.samui,
    ],
  };
});
